import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Copyright from '../components/Copyright';
import Logo from '../components/Logo';
import useStyles from '../helpers/styles';
import Wrapper from '../helpers/FormWrapper';
import * as CognitoService from '../services/cognito.service.js';
import '../helpers/getDestUrl';

const ResetPasswordForm = () => {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [code, setCode] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [requestCode, setRequestCode] = useState(true);
  const [feedback, setFeedback] = useState(null);

  const onSubmit = () => {
    if (!areFieldsValid()) {
      return;
    }

    if (requestCode) {
      CognitoService.forgotPassword(email)
        .then(function () {
          setRequestCode(false);
        })
        .catch(function (error) {
          setEmailError(error.message);
        });
    }
    else {
      CognitoService.confirmPassword(code, email, password)
        .then(function () {
          setFeedback('Password has been reset!');
          setTimeout(() => { navigate('/') }, 2000);
        })
        .catch(function (error) {
          switch (error.code) {
            case 'CodeMismatchException':
            case 'ExpiredCodeException':
              setCodeError(error.message);
              break;
            default:
              setPasswordError(error.message);
          }
        });
    }
  }

  const showSubmitButton = () => {
    return (
      <Button
        type="button"
        key="submitButton"
        onClick={onSubmit}
        fullWidth
        variant="contained"
        color="primary"
        className="submit-btn"
      >
        Continue
      </Button>
    );
  }

  const workflow = () => {
    if (requestCode) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              error={Boolean(emailError)}
              helperText={emailError}
              id="email"
              label="Email Address"
              name="email"
              onChange={ ({target}) => { setEmail(target.value) }}
            />
          </Grid>
          <Grid item xs={12}>
            { showSubmitButton() }
          </Grid>
        </Grid>
      )
    }
    else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
                Please enter the verification code we sent to your email {email}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="code"
              onChange={ ({target}) => { setCode(target.value) }}
              variant="outlined"
              required
              fullWidth
              error={codeError}
              helperText={codeError}
              id="code"
              label="Code"
              placeholder="-----"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required fullWidth
              error={Boolean(passwordError)}
              helperText={passwordError}
              label="Password"
              name="password"
              onChange={ ({target}) => { setPassword(target.value) }}
              type="password"
              id="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="confirm_password"
              onChange={ ({target}) => { setConfirmPassword(target.value) }}
              label="Confirm Password"
              type="password"
              id="confirm_password"
            />
          </Grid>
          <Grid item xs={12}>
            { showSubmitButton() }
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
             Didn't receive a verification&nbsp;code?
             <Link onClick={resendCode}>
               &nbsp;Re-send
             </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {feedback}
            </Typography>
          </Grid>
        </Grid>
      )
    }
  }

  const resendCode = () => {
    CognitoService.resendConfirmationCode()
      .then(function () {
        setFeedback('New code has been sent to your email.');
      })
      .catch(function (error) {
        setFeedback(error.message);
      });
  };

  const areFieldsValid = () => {
    resetErrors();
    var valid = true;

    if (requestCode) {
      if (!email.trim()) {
        setEmailError('Invalid Email.');
        valid = false;
      }
    }
    else {
      if (!password || password.length < 8) {
        setPasswordError(`Password must be at least 8 characters long.
          and should include numbers, uppercase and lowercase letters`);
        valid = false;
      }
      else if (password !== confirmPassword) {
        setPasswordError('Passwords do not match.');
        valid = false;
      }
    }

    return valid;
  };

  const resetErrors = () => {
    setEmailError(null);
    setPasswordError(null);
  };

  return (
    <div className={classes.paper}>
      <Logo />
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <form className={classes.form} noValidate>
        { workflow() }
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  );
}

export default function () {
  return (
    <Wrapper>
      <ResetPasswordForm />
    </Wrapper>
  );
}
